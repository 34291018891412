.tree-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 40px 0;

  .tree-node {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    margin: 20px;

    .node-content {
      // background: #4a90e2;
      background: #2c3039;
      color: #fff;
      padding: 20px;
      border-radius: 8px;
      text-align: center;
      box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
      transition: transform 0.3s ease;
      //   border: 1px solid #4a90e2;
      h3 {
        margin: 0 0 10px 0;
        font-size: 1.2rem;
      }

      p {
        margin: 0;
        font-size: 1rem;
      }

      &:hover {
        transform: translateY(-10px);
      }
    }
  }

  .root-node {
    .branch {
      display: flex;
      justify-content: space-between;
      width: 100%;
      margin-top: 20px;
      position: relative;

      &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 50%;
        width: 2px;
        height: 20px;
        background: #4a90e2;
        transform: translateX(-50%);
      }
    }

    .tree-node {
      &:not(:first-child)::before {
        content: "";
        position: absolute;
        top: -20px;
        left: 50%;
        width: 2px;
        height: 20px;
        background: #4a90e2;
        transform: translateX(-50%);
      }

      &:first-child::before {
        content: "";
        position: absolute;
        top: -20px;
        left: 50%;
        width: 2px;
        height: 20px;
        background: #4a90e2;
        transform: translateX(-50%);
      }

      &::after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 2px;
        background: #4a90e2;
      }
    }
  }

  @media (max-width: 768px) {
    .root-node {
      .branch {
        flex-wrap: wrap;
        width: 100%;
        .child-node {
          width: 100%;
        }
        .node-content {
          width: 100%;
          h3 {
            font-size: 16px !important;
          }
        }
        .tree-node {
          // width: 50%;
          width: 100%;
          box-sizing: border-box;
          &:nth-child(odd)::after {
            width: 50%;

            width: 100%;
          }

          &:nth-child(even)::after {
            width: 0;
          }

          &:nth-child(odd)::before {
            left: 50%;
          }

          &:nth-child(even)::before {
            left: 50%;
          }
        }
      }
    }
  }

  @media (max-width: 480px) {
    .tree-node {
      .node-content {
        padding: 15px;
        font-size: 0.9rem;

        h3 {
          font-size: 1rem;
        }

        p {
          font-size: 0.8rem;
        }
      }
    }
  }
}
