@import "../__variables";

.default-modal {
  padding: 1.5rem;
  background-color: #15181d;
  border: 0.5px solid #0270d7;
  border-radius: $border-radius !important;

  // @media screen and (max-width: $medium-screen) {
  //   bottom: 0px !important;
  //   position: fixed;
  //   right: 10px;
  //   left: 10px;
  // }

  .icon {
    margin-left: auto;
    display: block;
    width: fit-content;
    color: $cancel-color;
    cursor: pointer;
    font-size: 1.5rem;
  }
  .close--button {
    width: 100%;
    background-color: $primary-color;
    height: 3rem;
    font-weight: 600;
    margin-top: 2rem;
  }
}

.DialogOverlay {
  background-color: #15181d;
  position: fixed;
  inset: 0;
  animation: overlayShow 150ms cubic-bezier(0.16, 1, 0.3, 1);
}

.DialogContent {
  background: #15181d;
  border-radius: 6px;
  box-shadow: hsl(206 22% 7% / 35%) 0px 10px 38px -10px,
    hsl(206 22% 7% / 20%) 0px 10px 20px -15px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  z-index: 100000;
  animation: contentShow 150ms cubic-bezier(0.16, 1, 0.3, 1);
  @media screen and (max-width: 768px) {
    width: calc(100% - 2rem);
  }
}

.DialogContent:focus {
  outline: none;
}

.DialogTitle {
  margin: 0;
  font-weight: 500;
  color: var(--mauve-12);
  font-size: 17px;
}

.DialogDescription {
  margin: 10px 0 20px;
  color: var(--mauve-11);
  font-size: 15px;
  line-height: 1.5;
}

@keyframes overlayShow {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes contentShow {
  from {
    opacity: 0;
    transform: translate(-50%, -48%) scale(0.96);
  }
  to {
    opacity: 1;
    transform: translate(-50%, -50%) scale(1);
  }
}
